import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { ContributorModel, OrganizationModel } from "../../domain/model/OrganizationModel";
import { OrganizationRepository } from "../../domain/OrganizationRepository";
import {
  ADD_ORGANIZATION_CONTRIBUTOR,
  BILLING_PORTAL_SESSION,
  CREATE_ORGANIZATION_MUTATION,
  DELETE_ORGANIZATION_CONTRIBUTOR,
  GET_ORGANIZATIONS_QUERY
} from "./OrganizatioQueries";
import { MemoryStorage } from "@/infrastructure/cache/MemoryStorage";

export class OrganizationRepositoryImpl implements OrganizationRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async createOrganization(organizationName: string): Promise<ResultModel<OrganizationModel>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: CREATE_ORGANIZATION_MUTATION,
        variables: {
          input: {
            name: organizationName
          }
        }
      });
      return <ResultModel<OrganizationModel>>{
        onSuccess: this.mapToModel(result.data.createOrganization)
      };
    } catch (error: any) {
      return <ResultModel<OrganizationModel>>{
        onError: error.message
      };
    }
  }

  async deleteOrganizationContributor(organizationId: string, contributorEmail: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: DELETE_ORGANIZATION_CONTRIBUTOR,
        variables: {
          input: {
            organizationId: organizationId,
            contributorEmail: contributorEmail
          }
        }
      });
      return <ResultModel<any>>{
        onSuccess: {}
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message
      };
    }
  }

  async addOrganizationContributor(organizationId: string, contributorEmail: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: ADD_ORGANIZATION_CONTRIBUTOR,
        variables: {
          input: {
            organizationId: organizationId,
            contributorEmail: contributorEmail
          }
        }
      });
      return <ResultModel<any>>{
        onSuccess: {}
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message
      };
    }
  }

  async getOrganizations(): Promise<ResultModel<OrganizationModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_ORGANIZATIONS_QUERY
      });

      const list: OrganizationModel[] = result?.data?.organizations?.map((item?: any) => {
        return this.mapToModel(item);
      });

      return <ResultModel<OrganizationModel[]>>{
        onSuccess: list
      };
    } catch (error: any) {
      return <ResultModel<OrganizationModel[]>>{
        onError: error.message
      };
    }
  }

  async getBillingPortalSession(organizationId: string): Promise<ResultModel<string>> {
    try {
      const result = await this.graphqlClient.query({
        query: BILLING_PORTAL_SESSION,
        variables: {
          organizationId: organizationId
        }
      });
      return <ResultModel<string>>{
        onSuccess: result.data.billingPortalSession
      };
    } catch (error: any) {
      return <ResultModel<string>>{
        onError: error.message
      };
    }
  }

  changeCurrentOrganization(organizationId: string): void {
    MemoryStorage.getInstance().setItem("organizationId", organizationId);
    localStorage.setItem("organizationId", organizationId);
  }

  getCurrentOrganization(): string {
    const memory = MemoryStorage.getInstance().getItem("organizationId");
    if (memory) return memory;
    return localStorage.getItem("organizationId") ?? "";
  }

  private mapToModel(item?: any): OrganizationModel {
    return <OrganizationModel>{
      id: item?.id ?? "",
      name: item?.name ?? "",
      manageable: item?.manageable ?? false,
      contributors: item?.contributors?.map((contributor: any) => {
        return {
          role: contributor.role,
          id: contributor.id,
          email: contributor.user.email
        } as ContributorModel;
      })
    };
  }
}
